<template>
  <div class="main-wrap">
    <!-- 导航栏 -->
    <!-- 表单查询 -->
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-form-item>
        <el-input
          v-model="queryForm.groupName"
          placeholder="请输入分组名"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="queryData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="openAddPage"
          >添加落地页分组</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 数据表 -->
    <el-table
      v-loading="loading"
      border
      stripe
      :data="tableData"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="1000"
    >
      <el-table-column
        prop="id"
        align="center"
        label="ID"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="groupName"
        label="分组名称"
        min-width="160"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="nums"
        label="落地页数量"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.nums ? scope.row.nums : 0 }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        align="center"
        min-width="120"
      ></el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        min-width="160"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="initOpenAddPage(scope.row.id)"
            >编辑</el-button
          >
          <el-button size="mini" type="text" @click="cloneEdit(scope.row.id)"
            >复制</el-button
          >
          <el-button size="mini" type="text" @click="deleteData(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加 -->
    <el-dialog
      :title="isEdit ? '编辑' : '添加'"
      :visible.sync="dialogAdd"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
      :before-close="clearAddForm"
    >
      <el-form
        ref="dictAddForm"
        :model="dictAddForm"
        label-width="80px"
        :rules="dictAddFormRules"
        status-icon
        size="medium"
      >
        <el-form-item label="分组名称" prop="groupName">
          <el-input v-model="dictAddForm.groupName"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="dictAddForm.remark" type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="clearAddForm">取消</el-button>
          <el-button type="primary" @click="addData">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 分页 -->
    <el-pagination
      background
      :current-page.sync="queryForm.pageNo"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size.sync="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="queryData"
      @current-change="queryData"
      @prev-click="queryData"
      @next-click="queryData"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "AddDeclare",
  data() {
    return {
      queryForm: {
        groupName: null,
        groupId: null,
        pageNo: 1,
        pageSize: 10,
      },
      tableData: [],
      loading: false,
      total: 0,
      // 添加
      isEdit: false,
      dialogAdd: false,
      dictAddForm: {
        groupName: null,
        remark: null,
      },
      dictAddFormRules: {
        groupName: [
          { required: true, message: "请输入分组名称", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    // 查询
    queryData() {
      this.$http
        .get("/system/landingGroup/getPage", { params: this.queryForm })
        .then((response) => {
          if (response.data.code === 200) {
            this.tableData = response.data.data;
            this.total = response.data.count;
          }
        });
    },
    // 添加
    openAddPage() {
      this.dictAddForm = {
        groupName: null,
        remark: null,
      };
      this.dialogAdd = true;
    },
    // 编辑
    initOpenAddPage(id) {
      this.isEdit = true;
      this.$http
        .get("/system/landingGroup/getDetail?id=" + id)
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data.data;
            this.dictAddForm = {
              id: id,
              groupName: data.groupName,
              remark: data.remark,
            };
          }
        });
      this.dialogAdd = true;
    },
    // 添加编辑提交
    addData() {
      this.$refs.dictAddForm.validate((valid) => {
        if (valid) {
          let url;
          let str;
          if (this.dictAddForm.id) {
            url = "/system/landingGroup/edit";
            str = "编辑";
          } else {
            url = "/system/landingGroup/add";
            str = "添加";
          }
          this.$http
            .post(url, this.dictAddForm)
            .then((response) => {
              if (response.data.code === 200) {
                this.dialogAdd = false;
                this.$message({
                  message: str + "成功",
                  type: "success",
                  duration: 1000,
                  onClose: () => {
                    this.clearAddForm();
                    this.queryData();
                  },
                });
              }
            })
            .finally(() => {
              this.isEdit = false;
            });
        }
      });
    },
    // 复制
    cloneEdit(e) {
      this.dialogAdd = true;
      this.$http
        .get("/system/landingGroup/getDetail?id=" + e)
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data.data;
            this.dictAddForm = {
              groupName: data.groupName,
              remark: data.remark,
            };
          }
        });
    },
    // 删除
    deleteData(id) {
      this.$confirm("是否删除这条落地页分组？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .delete("/system/landingGroup/delete", { params: { id: id } })
          .then((response) => {
            if (response.data.code === 200) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 1000,
                onClose: () => {
                  this.queryData();
                },
              });
            }
          });
      });
    },
    clearAddForm() {
      this.$refs.dictAddForm.resetFields();
      this.dialogAdd = false;
      this.isEdit = false;
    },
  },
};
</script>
<style scoped></style>
